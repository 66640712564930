"use client";


import { APIOptionalUser } from "@/ts/business/api/api_schema";
import { UserProvider } from "@/app_components/user/UserContext";
import Header from "@/app_components/layout/Header";
import styles from "@/app/not-found.module.scss";
import Link from "next/link";
import BackArrow from "@/app_components/icon/BackArrow";
import Footer from "@/app_components/layout/Footer";


interface NotFoundProps {
    user: APIOptionalUser;
}


export function NotFound({ user }: NotFoundProps) {
    return (
        <UserProvider user={user}>
            <main>
                <title>Not Found - RoyalUr.net</title>
                <Header />
                <section className={styles.not_found}>
                    <h1>Page Not Found</h1>
                    <p>I&apos;m afraid this page has been lost to history!</p>
                    <p>
                        <Link href="/">
                            <BackArrow />
                            Take me back to the homepage.
                        </Link>
                    </p>
                </section>
                <Footer />
            </main>
        </UserProvider>
    );
}
